<template>
    <div :class="`rn-accordion-style  accordion`">
        <div :id="id" style="margin: 30px 0;">
            <div style="margin: 15px; background: #ffffff;" class="card" v-for="(content, index) in accordionContent">
                <h2 class=" accordion-header card-header " style="overflow: hidden; padding: 20px 20px;" :id="`heading${content.id}`">
                    <div class="title-pre">
                        <div class="title-row" style="align-items: flex-start;">
                            <img class="logo-dark" :src="require(`@/assets/images/logo/${content.image}.png`)" />
                            <div class="title-column">
                                <div style="color: #000000;font-size: 20px;"> {{ content.brandName }}</div>
                                <div style="color: #999999;font-size: 12px; font-weight: normal; margin-top: 8px;"> {{ content.barndContent }}</div>
                            </div>
                        </div>
                        <div class="button" @click="toApp(index)">
                            {{$t('downloaded')}}
                        </div>
                    </div>
                </h2>
                <div :id="`collapse${content.id}`" class="accordion-collapse collapse" :class="{ 'show': index === -1 }"
                    :aria-labelledby="`heading${content.id}`" :data-bs-parent="`#${id}`">
                    <div class="accordion-body card-body" style="border-top:0.5px solid #D9E3E7">
                        <div v-if="content.description != ''" v-html="content.description"></div>
                        <div v-else>{{ $t('loading') }}</div>
                    </div>
                </div>

                <div class="card-header" style="border-top:0.5px solid #D9E3E7; padding: 10px 30px;">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        :data-bs-target="`#collapse${content.id}`"
                        :aria-expanded="index === activeCollapse ? 'true' : 'false'"
                        :aria-controls="`collapse${content.id}`" @click="activeCollapse = index; getArticle(index)">
                        <div style="margin: 0 auto ;">
                            <div class="title-row">
                                <div style="padding-right: 5px; font-size: 16px;"> {{ $t('howToUse') }} </div>
                                <i class="collapse-icon" style="height: 12px; width: 12px; color: #D9E3E7;"/>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import MobileDetect from "mobile-detect"
export default {
    name: 'Accordion',
    props: {
        id: {
            type: String,
            default: "download"
        },
        accordionStyleClass: {
            type: String
        }
    },
    data() {
        return {
            activeCollapse: -1,
            accordionContent:[]
        }
    },
    created() {
        this.accordionContent.push(...[
            {
                id: 'One',
                image: 'hitgo',
                brandName: 'HitGo',
                barndContent: this.$t("fitshoUpDesc"),
                description: '',
                google: "com.hitgo",
                ios: "6608972308"
            }
        ])
    },
    methods: {
        toApp(index) {
            if (/android/i.test(navigator.userAgent)) {
                this.openUrl("https://play.google.com/store/apps/details?id="+this.accordionContent[index].google)
            } else if (/ipad|iphone|mac/i.test(navigator.userAgent)) {
                this.openUrl("https://itunes.apple.com/app/fitshow/id"+this.accordionContent[index].ios);
            }
        },
        openUrl(url) {
            window.location.href = url;
        },
        async getArticle(index) {
            if (this.accordionContent[index].description == "") {
                let lang = "en";
                if (this.$i18n.locale == "zh") {
                    lang = "zh_cn"
                }
                const res = await this.apis.getArticle({ "identify": this.accordionContent[index].image, "lang": lang });
                if (res.code == 1 && res.data.id > 0) {
                    this.accordionContent[index].description = res.data.content
                }
            }
        }
    }
}
</script>
<style scoped lang="scss">
.accordion {
    height: 100vh;
    overflow: auto;
    background-color: #eeeeee;
}
// .accordion{
//     height: 100vh;
//     overflow-y: scroll;
//     background-color: #eeeeee;
// }
.container {
    align-items: center;
}
.card{
    background: #ffffff;
}
.title-pre {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logo-dark {
    height: auto;
    width: auto;
}

.title-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 15px;
}

.button {
    background-color: #EEEEF0;
    color: #0079ff;
    padding: 8px 20px;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 30px;
    text-align: right;
    cursor: pointer;
}
</style>