export default {
    meta: {
        fitshow: "运动秀-智能化运动设备解决方案",
        article: "文章",
        appDownload: "应用下载",
        contact: "联系我们",
        about: "关于我们",
        fitshowApp: "运动秀APP",
        oemApp: "OEM APP",
        embeddedApp: "品牌入驻APP",
        qrOta: "Ota Tools",
        logoutApp: "删除APP账号"
    },
    lang: "语言",
    fitshow: "运动秀",
    learnMore:"了解更多",
    home:"首页",
    products:"硬件产品",
    software:"软件开发",
    apps:"App服务",
    download:"资料下载",
    about:"关于我们",
    contact:"联系我们",
    smartHardware:"智能硬件",
    smartHardwareDetail:"运动秀硬件研发多种智能模块，包括蓝牙、WiFi、语音、红外、IR、NFC。",
    appService:"APP服务",
    appServices:"APP服务",
    appServiceDetail:"通过运动秀智能硬件，与FitShow APP、Kinomap、Onelap、Zwift和支持FTMS协议的APP完美兼容。",
    appCustom:"APP定制",
    appCustomDetail:"我们在运动秀APP中提供品牌推广服务，同时也提供独立应用程序的定制开发。",
    smartMadeEasy:"让智能变得简单",
    smartMadeEasyDetail:"运动秀为需要联网运动产品的客户提供智能和综合解决方案。",
    servicesProvideForYou:"我们的服务",
    servicesProvideForYouDetail:"运动秀的服务包括智能硬件研发、APP服务 <br /> 和软件定制开发服务。",
    hardwareDevelopment:"硬件研发",
    hardwareDevelopmentDetail:"嵌入式开发、通信协议开发、SDK开发、Lisence授权。",
    softwareDevelopment:"软件开发",
    softwareDevelopmentDetail:"APP定制开发、OEM APP、SDK开发、云数据开发、数据分析、SaaS服务",
    appServicesDetail1:"运动秀硬件与FitShow、Kinomap、Onelap、Zwift完美兼容。",
    ourProducts:"我们的产品",
    bluetoothModule:"蓝牙模块",
    bluetoothModuleDetail:"单模，双模，低功耗",
    smartKnob:"智能旋钮",
    smartKnobDetail:"智能蓝牙旋钮控制器",
    wirelessController:"无线控制器",
    wirelessControllerDetail:"双蓝牙无线控制器",
    sisplaySystem:"彩屏系统",
    sisplaySystemDetail:"TFT屏幕，安卓系统开发",
    heartRateMonitor:"心率监测器",
    heartRateMonitorDetail:"心率手环和胸带",
    resistanceRegulator:"电阻调节器",
    resistanceRegulatorDetail:"滑动阻力调节",
    allInOneApp:"运动秀APP",
    allInOneAppDetail:"我们提供一个综合的运动APP：运动秀。它是一个综合性的室内健身应用，您可以免费使用，没有任何运营和维护费用，您只需要开发硬件，不需要在软件研发上投入额外的资源。该应用可与各种运动场景和设备整合，并有多种语言版本，包括中文、英文、德文、法文、意大利文、西班牙文、俄文、日文和韩文，使您更容易进入全球运动器材市场。",
    brandService:"品牌服务",
    brandServiceDetail:"您可以通过运动秀App管理用户，可以在运动秀App内展示产品、查看用户详情、查看每个会员的进度、与会员进行1-1聊天、处理客户的问题反馈、用户数据分析、锻炼表现-导出/导入Excel表、批量用户通知等。",
    oemAPP:"OEM APP",
    oemAPPDetail:"基于运动秀App的所有功能重新设计品牌专属UI界面。使用运动秀SDK快速开发一个定制的智能运动APP。",
    fitnessAppDevelopment:"APP定制开发",
    fitnessAppDevelopmentDetail:"根据品牌需求，进行深度软件定制开发，包括App开发、SDK开发、MCU开发、云端开发、数据分析等。",
    fitshowApp:"运动秀App",
    fitshowAppDetail:"使用运动秀蓝牙模组的设备可以免费使用，没有任何运营和维护费用。",
    originalApp:"基础版",
    originalAppDetail:"运动秀通用界面及内容展示，不支持后台管理系统。",
    brandingServices:"升级版",
    brandingServicesDetail:"专属品牌界面及信息展示，专属后台用户管理。",
    thirdPartyApp:"第三方APP",
    thirdPartyAppDetail:"运动秀蓝牙模块与Kinomap、Onelap、Zwift和支持FTMS协议的APP完美兼容。",
    smartEnabledDevices:"智能化的设备",
    oemPartners:"OEM合作伙伴",
    brandPartners:"品牌合作伙伴",
    regionalDistribution:"地区分布",
    servicesByNumbers:"数字化的服务",
    powersCustomersWorldwide:"为全球客户提供动力",
    lang:"语言",
    privacyPolicy:"隐私政策",
    termsAndCondition:"服务条款",
    contactUs:"联系我们",
    downloaded: "下载",
    howToUse: "使用教程",
    loading: "加载中...",
    fitshowUp: "运动秀",
    fitshoUpDesc: "智能运动健康",
    address: "福建省厦门市集美区软件园三期B04栋1302室",
    aboutFs: "关于我们",
    aboutFsDesc: "8年多来，我们一直致力于运动设备智能化服务，为客户提供快速、低成本的智能解决方案。",
    ourVision: "企业愿景",
    ourVisionDesc: "全球运动设备智能化解决方案提供商",
    ourMission: "企业使命",
    ourMissionDesc: "为客户提供软硬件一体化解决方案，轻松实现智能化",
    ourValues: "企业价值观",
    ourValuesDesc: "成就客户、合作共赢、专业敬业、自我成长",
    globalEquipmentProvider: "全球运动设备智能化解决方案提供商",
    globalEquipmentProviderDesc: "运动秀是一个智能运动器材物联网开发平台，具有独特的云+连接+应用的一体化服务，使品牌商、零售商和原始设备制造商能够轻松、经济地实现其产品智能化。运动秀平台已经为数百家企业的500多万台设备提供了智能支持，产品销售到全球120多个国家和地区。",
    contactType: "联系我们",
    contactNum: "联系电话",
    contactEmail: '邮箱地址',
    contactAddr: "公司地址",
    appService: "软件服务",
    fitshowDesc: "我们提供一个综合的运动APP：运动秀。它是一个综合性的室内健身应用，您可以免费使用，没有任何运营和维护费用，您只需要开发硬件，不需要在软件研发上投入额外的费用。",
    aboutApp: "关于APP",
    supportEquipment: "支持设备",
    supportEquipmentDesc: "跑步机、室内单车、划船机和椭圆机等",
    supportLang: "支持语言",
    supportLangDesc: "简体中文、英语、德语、法语、意大利语、西班牙语、俄语、日语和韩语",
    features: "功能模块",
    featuresDesc: "运动模式、课程计划、健康数据、饮食记录等",
    price: "费用情况",
    priceDesc: "您的设备可以免费接入，无需任何运营及维护费用",
    featuresIntro: "APP功能介绍",
    quickStart: "快速开始",
    quickStartDesc: "多人实时在线运动",
    structured: "课程训练",
    structuredDesc: "科学的结构化训练",
    customWorkout: "自定义程序",
    customWorkoutDesc: "根据自己的目标量身定制",
    sceneWorkout: "风景视频",
    sceneWorkoutDesc: "探索自然风景，从美丽的森林到令人敬畏的山脉",
    planWorkout: "运动计划",
    planWorkoutDesc: "由教练设计的工作计划旨在帮助你实现中短期健身目标",
    healthWorkout: "健康数据",
    healthWorkoutDesc: "每日目标概览，通过健康 App 同步和导入运动数据",
    customeApp: "APP定制",
    customeAppTitle: "品牌专属APP定制服务",
    customeAppDesc: "APP OEM定制或品牌入驻运动秀APP",
    learnMore: "了解详情",
    embeddedApp: "品牌入驻APP",
    embeddedAppDesc: "将您的品牌入驻至运动App，我们将提供品牌专属后台管理系统，您可以在此发布自己的banner广告、教练视频、商店产品、推广软文等。",
    aboutFsApp: "运动秀APP介绍",
    oemAppDesc: "基于运动秀APP所有功能和框架的基础上定制品牌自己的APP，使用 运动秀蓝牙 SDK 和UI界面组件快速开发定制智能健身APP。",
    deleteAppAccounut: "你确定要注销你的账号吗？",

    ifDelete: "如果你注销了账号：",
    ifDeleteTipsOne: "你会失去运动秀应用程序中该账号下的所有数据。在你提交申请后，我们将禁用你的账号。完全删除你的所有数据可能需要30天时间。",
    ifDeleteTipsTwo: "一旦你注销了账号，你就会退出登录应用程序。",
    ifDeleteTipsThree: "如果你改变主意，你可以随时回来注册一个新账号。",
    inputEmailTip: "请输入邮箱",
    inputPassTip: "请输入密码",
    submit:"提交",
    errorEmail: "邮箱格式错误",
    errorPasss: "密码格式错误",
    subSuccess: "提交成功",
    authCert: "认证证书",
    productCert: "产品规格书",
    commProto: "通讯协议",
    technicalData: "技术资料",
    kinomapUpDesc: "室内骑行、跑步",
    zwiftUpDesc: "骑行与跑步"
}